import splide from "@splidejs/splide";

const initialiseSliders = () => {
  const splideInstance = document.querySelectorAll(
    ".splide"
  ) as NodeListOf<HTMLElement>;
  if (splideInstance.length > 0) {
    for (let i = 0; i < splideInstance.length; i++) {
      new splide(splideInstance[i]).mount();
    }
  }
};

export default initialiseSliders;
