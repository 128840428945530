export default class loadMore {
  loadMoreAttr: string = "data-load-more";
  postListAttr: string = "data-post-list";
  itemAttr: string = "data-post-item";
  constructor() {
    if (!this.loadMoreButton) return;

    this.registerEvents();
  }
  // Private methods
  registerEvents() {
    this.loadMoreButton.addEventListener("click", () => {
      this.loadMore();
    });
  }
  loadMore() {
    try {
      const nextUrl = this.loadMoreButton.getAttribute("data-next");
      if (!nextUrl) return;

      this.setState(true);

      fetch(nextUrl, {
        method: "GET",
      })
        .then((response) => response.text())
        .then((html) => {
          if (!html) return;
          if (!this.postsContainer) return;

          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");

          const items = this.getItems(doc);
          items.forEach((post) => {
            this.postsContainer.appendChild(post);
          });

          const next = this.getLoadMoreButton(doc);
          const nextValue = next?.getAttribute("data-next");
          if (nextValue)
            this.loadMoreButton.setAttribute("data-next", nextValue);

          if (!next) this.loadMoreButton.remove();

          this.setState(false);
        });
    } catch (err) {
      console.log(err);
      this.setState(false);
    }
  }
  getItems(document: Document) {
    return document.querySelectorAll(
      `[${this.itemAttr}]`
    ) as NodeListOf<HTMLElement>;
  }
  getLoadMoreButton(document: Document) {
    return document.querySelector(
      `[${this.loadMoreAttr}]`
    ) as HTMLButtonElement;
  }
  setState(loading: boolean) {
    if (loading) {
      this.loadMoreButton?.setAttribute("disabled", "disabled");
      this.loadMoreButton?.classList.add("loading");
    } else {
      this.loadMoreButton?.removeAttribute("disabled");
      this.loadMoreButton?.classList.remove("loading");
    }
  }
  // Getters
  get loadMoreButton() {
    return this.getLoadMoreButton(document);
  }
  get postsContainer() {
    return document.querySelector(`[${this.postListAttr}]`) as HTMLDivElement;
  }
}
