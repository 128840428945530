const initialiseReciteMe = () => {
    window.addEventListener('load', () => {
        const translationButton = document.getElementById("recite-translation-button") as HTMLButtonElement;
        const translationDropdown = document.getElementById("recite-translation-dropdown") as HTMLDivElement;
    
        if (translationButton && translationDropdown) {
            translationButton.addEventListener("click", function () {
                translationDropdown.style.display =
                translationDropdown.style.display == "none" ? "block" : "none";
            });
    
            document.addEventListener("click", function () {
                if (translationDropdown.style.display != "none") {
                    translationDropdown.style.display = "none";
                }
            }, true);
        }
    });
};

export default initialiseReciteMe;