const languageDropdown = () => {
  const globeBtn = document.getElementById("globe-btn") as HTMLButtonElement;
  if (!globeBtn) return;

  globeBtn.addEventListener("click", () => {
    const languageSelect = document.querySelector(
      ".gt_selector"
    ) as HTMLSelectElement;
    if (!languageSelect) return;
    languageSelect.click();
  });
};

export default languageDropdown;
