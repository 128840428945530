const initialiseFormToggles = () => {
  const buttons: NodeListOf<HTMLButtonElement> =
    document.querySelectorAll("[data-form-btn]");
  const contents: NodeListOf<HTMLElement> = document.querySelectorAll(
    "[data-form-content]"
  );

  const resetActiveStates = () => {
    contents.forEach((content) => {
      content.classList.remove("active");
      content.classList.add("hidden");
    });
    buttons.forEach((btn) => {
      btn.classList.remove("active");
    });
  };

  const onFormButtonClicked = (event: Event) => {
    const button = event.currentTarget as HTMLButtonElement;
    const targetId = button.dataset.formBtn;

    resetActiveStates();

    button.classList.add("active");
    const activeContent = document.querySelector(
      `[data-form-content="${targetId}"]`
    ) as HTMLElement;
    activeContent.classList.add("active");
    activeContent.classList.remove("hidden");
  };

  buttons.forEach((button) => {
    button.addEventListener("click", onFormButtonClicked);
  });
};

export default initialiseFormToggles;
