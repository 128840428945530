const updateResults = (form: HTMLFormElement, bdms: any[]) => {
  const container = form.querySelector("[bdm-result-con]") as HTMLElement;
  const noResults = form.querySelector("[bdm-no-result-con]") as HTMLElement;
  if (!container || !noResults) return;

  container.classList.add("hidden");
  noResults.classList.add("hidden");

  if (bdms.length === 0) {
    noResults.classList.remove("hidden");
    return;
  }

  const bdm = bdms[0];
  const data = {
    name: bdm.title.rendered,
    title: bdm.acf.bdm_title,
    mobile: bdm.acf.bdm_mobile,
    email: bdm.acf.bdm_email,
    image: bdm.large_thumbnail_url,
  };

  const name = container.querySelector("[bdm-result-name]") as HTMLElement;
  const title = container.querySelector("[bdm-result-title]") as HTMLElement;
  const mobile = container.querySelector(
    "[bdm-result-mobile]"
  ) as HTMLAnchorElement;
  const email = container.querySelector(
    "[bdm-result-email]"
  ) as HTMLAnchorElement;
  const image = container.querySelector(
    "[bdm-result-image]"
  ) as HTMLImageElement;

  name.textContent = data.name;
  title.textContent = data.title;
  mobile.textContent = data.mobile;
  mobile.href = `tel:${data.mobile}`;
  email.textContent = data.email;
  email.href = `mailto:${data.email}`;
  image.src = data.image;
  image.alt = data.name;

  container.classList.remove("hidden");
};
const resetForm = (form: HTMLFormElement) => {
  const container = form.querySelector("[bdm-result-con]") as HTMLElement;
  const noResults = form.querySelector("[bdm-no-result-con]") as HTMLElement;
  if (!container || !noResults) return;

  container.classList.add("hidden");
  noResults.classList.add("hidden");
};

const initliseBDMForm = () => {
  const form = document.querySelector("#bdm-form") as HTMLFormElement;
  if (!form) return;

  let debounceTimeout: ReturnType<typeof setTimeout>;

  form.addEventListener("input", () => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      const event = new Event("submit", {
        bubbles: true,
        cancelable: true,
      });
      form.dispatchEvent(event);
    }, 500);
  });

  form.addEventListener("submit", (e) => {
    e.preventDefault();

    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());
    const postcode = data.bdm_postcode;
    const url = form.action;

    form.classList.add("loading");

    if (!postcode) {
      form.classList.remove("loading");
      return resetForm(form);
    }

    fetch(`${url}?postcode=${postcode}`)
      .then((response) => response.json())
      .then((bdms) => {
        updateResults(form, bdms);
        form.classList.remove("loading");
      })
      .catch((error) => {
        console.error("Error:", error);
        form.classList.remove("loading");
      });
  });
};

export default initliseBDMForm;
