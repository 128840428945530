import Alpine from 'alpinejs'
import NavigationDropdown from "./classes/navigation-dropdown.js";
import NavigationBar from "./classes/navigation-bar.js";
import languageDropdown from "./functions/language-dropdown.js";
import loadMore from "./classes/load-more.js";
import initialiseForms from "./functions/forms.js";
import initialiseSliders from "./functions/initialise-sliders.js";
import initialiseTimelines from "./functions/initialise-timelines.js";
import initialiseFormToggles from "./functions/initialise-form-toggles.js";
import initialiseBDMForm from "./functions/bdm-form.js";
import initialiseReciteMe from './functions/reciteme.js';

new NavigationDropdown();
new NavigationBar();
new loadMore();
Alpine.start()

languageDropdown();
initialiseSliders();
initialiseForms();
initialiseTimelines();
initialiseFormToggles();
initialiseBDMForm();
initialiseReciteMe();
