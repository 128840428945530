export default class Timeline {
  itemAttr: string = "timeline-item";
  lineAttr: string = "timeline-line";
  lineInnerAttr: string = "timeline-line-inner";
  group: HTMLElement;
  constructor(ele: HTMLElement) {
    if (ele == null) {
      throw new Error("Timeline element is null");
    }
    this.group = ele;

    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  }
  updateLineInnerHeight = () => {
    const viewportCenter = window.innerHeight / 2;
    const totalHeight = this.line.getBoundingClientRect().height;
    const scrollY = window.scrollY;
    const lineTop = this.line.getBoundingClientRect().top + scrollY;

    const centerRelativeToLine = scrollY + viewportCenter - lineTop;

    const percentage = ((centerRelativeToLine / totalHeight) * 100).toFixed(2);
    this.lineInner.style.height = `${percentage}%`;
  };
  handleScroll = () => {
    const viewportCenter = window.innerHeight / 2;
    let centerItemFound = false;

    this.items.forEach((item) => {
      item.classList.remove("active");
    });

    for (let i = this.items.length - 1; i >= 0; i--) {
      const item = this.items[i];
      const rect = item.getBoundingClientRect();

      const bottom = rect.bottom - rect.height / 2;

      if (bottom < viewportCenter) {
        centerItemFound = true;
      }

      if (centerItemFound) {
        item.classList.add("active");
      }
    }

    // Update lineInner height
    this.updateLineInnerHeight();
  };
  // Getters
  get items(): HTMLElement[] {
    return Array.from(this.group.querySelectorAll(`[${this.itemAttr}]`));
  }
  get line(): HTMLElement {
    return this.group.querySelector(`[${this.lineAttr}]`) as HTMLElement;
  }
  get lineInner(): HTMLElement {
    return this.group.querySelector(`[${this.lineInnerAttr}]`) as HTMLElement;
  }
}
