interface NavGroup {
  active: boolean;
  item: HTMLElement;
  target: HTMLElement;
  hoverTimeout?: ReturnType<typeof setTimeout>;
  clearTimeout?: ReturnType<typeof setTimeout>;
}

const HOVER_TIMEOUT = 300;
const HOVER_OUT_TIMEOUT = 100;

export default class NaviationDropdown {
  itemAttr: string = "data-nav-item";
  targetAtt: string = "data-nav-target";
  groups: Map<string, NavGroup> = new Map();
  constructor() {
    this.buildMap();
    this.registerEvents();
  }
  // Private methods
  buildMap() {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];

      const uuid = item.getAttribute(this.itemAttr);
      if (!uuid) return;

      const target = document.querySelector(
        `[${this.targetAtt}="${uuid}"]`
      ) as HTMLElement;
      if (!target) return;

      this.groups.set(uuid, {
        active: false,
        item,
        target,
      });
    }
  }
  registerEvents() {
    const groups = this.groups.values();
    for (const group of groups) {
      group.item.addEventListener("click", (e) => {
        this.itemClickEvent(e, group);
      });
      group.item.addEventListener(
        "mouseenter",
        () => {
          this.hoverEvent(group);
        },
        { passive: true }
      );
      group.item.addEventListener(
        "mouseleave",
        (e) => {
          this.hoverOutEvent(e, group);
        },
        { passive: true }
      );
      group.target.addEventListener(
        "mouseleave",
        (e) => {
          this.hoverOutEvent(e, group);
        },
        { passive: true }
      );
    }
  }

  // Events
  hoverEvent(group: NavGroup) {
    if (group.hoverTimeout) clearTimeout(group.hoverTimeout);

    const timeout = setTimeout(() => {
      group.target.classList.add("active");
      group.item.classList.add("active");
      group.active = true;
    }, HOVER_TIMEOUT);
    group.hoverTimeout = timeout;
  }
  hoverOutEvent(e: MouseEvent, group: NavGroup) {
    const uuid = group.item.getAttribute(this.itemAttr);
    if (!uuid) return;

    // Check if the mouse is over the target or the item, recursive for parents
    const target = e.relatedTarget as HTMLElement;

    const checkParentForUUID = (element: HTMLElement): boolean => {
      if (!element) return false;
      if (element.getAttribute(this.targetAtt) === uuid) return true;
      if (element.getAttribute(this.itemAttr) === uuid) return true;
      if (!element.parentElement) return false;
      return checkParentForUUID(element.parentElement);
    };
    if (checkParentForUUID(target)) return;

    if (group.hoverTimeout) clearTimeout(group.hoverTimeout);

    if (group.clearTimeout) clearTimeout(group.clearTimeout);
    const timeout = setTimeout(() => {
      group.target.classList.remove("active");
      group.item.classList.remove("active");
      group.active = false;
    }, HOVER_OUT_TIMEOUT);
    group.hoverTimeout = timeout;
  }
  itemClickEvent(e: Event, group: NavGroup) {
    if (!group.active) e.preventDefault();
  }

  // Getters
  get items(): NodeListOf<HTMLElement> {
    return document.querySelectorAll(`[${this.itemAttr}]`);
  }
}
