import FormHandler, {
  GoogleV2,
  FlashMessage,
  EnhancedFileInput,
} from "../packages/formhandler";

const flashMessage = new FlashMessage("#flash-message", 7200);

new EnhancedFileInput(".file-upload", {
  classes: {
    hasFile: "has-file",
    dragOver: "drag-over",
  },
});

const initialiseForms = () => {
  const recaptchaMetaEle = document.querySelector(
    "meta[name='google_recaptcha_site_key']"
  ) as HTMLMetaElement;
  const recaptchaKey = recaptchaMetaEle.getAttribute("content");

  const forms = [
    ...document.querySelectorAll("[data-form-handler]"),
  ] as HTMLFormElement[];

  if (!recaptchaKey) {
    console.error("Missing google_recaptcha_site_key meta tag");
  }

  if (forms) {
    forms.forEach((form) => {
      const dataAlerts = JSON.parse(form.dataset.formAlerts || "{}");
      new FormHandler(`#${form.id}`, {
        recaptcha: new GoogleV2(recaptchaKey as string),
        flashMessage: flashMessage,
        localisation: {
          validationError: dataAlerts.error
            ? dataAlerts.error
            : "There was an error with your submission.",
          success: dataAlerts.success
            ? dataAlerts.success
            : "Thank you for your submission.",
          error: dataAlerts.error
            ? dataAlerts.error
            : "There was an error with your submission.",
        },
        scrollToError: {
          enabled: true,
          offset: 0,
        },
        validate: {
          onChange: true,
          onSubmit: true,
        },
        onSuccess: (form, res) => {
          flashMessage.flash(dataAlerts.success, true);
        },
        onError: (form, res) => {
          flashMessage.flash(dataAlerts.error, false);
        },
      });
    });
  }
};

export default initialiseForms;
