import timeline from "../classes/timeline.js";

const initialiseTimelines = () => {
  const timelineInstances = document.querySelectorAll(
    ".timeline-group"
  ) as NodeListOf<HTMLElement>;
  if (timelineInstances.length > 0) {
    timelineInstances.forEach((ele) => {
      new timeline(ele);
    });
  }
};

export default initialiseTimelines;
